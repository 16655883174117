<template>
  <div>
    <section>
      <TippCard
        hintID="Diensterfassung_Konfiguration_Dienstarten_1_V1"
        text="Dienstberichte basieren auf einer Berichtsart. Jede Berichtsart dient als Vorlage für einen Dienstbericht und kann verwendet werden, um Daten vorauszufüllen und spezifische Einstellungen festzulegen."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.dataImport.snackbar.model" timeout="5000"
      >{{ helpers.dataImport.snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-format-list-bulleted-type"
                title="Dienstarten"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="dutyTypeHeaders"
                  :items="dutyTypes"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  no-data-text="Keine Dienstarten vorhanden"
                  @click:row="openDutyType"
                >
                  <template v-slot:[`item.icon`]="{ item }">
                    <data-avatar
                      :icon="item.icon"
                      :color="item.color"
                      small
                    ></data-avatar>
                  </template>
                  <template v-slot:[`item.title`]="{ item }">
                    <span class="font-weight-medium">{{ item.title }}</span>
                    <small class="grey--text">{{ item.note }}</small>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Dienstarten importieren"
      text="Importierte Dienstarten stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImport.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Dienstarten suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                      <!-- <v-col
                        ><v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon left small>mdi-sort-variant</v-icon>
                              Sortieren nach

                              {{ eventsFilter }}
                              <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                          </template>
                          <v-list nav
                            v-model="eventsFilter">
                            <v-list-item-group mandatory>
                              <v-list-item link dense value="Alle">
                                <v-list-item-icon>
                                  <v-icon dense
                                    >mdi-sort-numeric-variant</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Sortierschlüssel</v-list-item-title
                                >
                              </v-list-item>
                              <v-list-item link dense value="Eigene">
                                <v-list-item-icon>
                                  <v-icon dense
                                    >mdi-sort-alphabetical-variant</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Alphabetisch</v-list-item-title
                                >
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu></v-col
                      >-->
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <small class="grey--text">{{ item.description }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_REPORT_TYPES, CREATE_REPORT_TYPES } from "@/store/action-types.js";
import { DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_create } from "@/data/permission-types.js";
import { importableDutyTypes } from "@/data/import/importable-dutyTypes.js";

export default {
  name: "duty-management-config-tab-duty-types",
  components: {
    TippCard,
    Subheader,
    CustomDialog,
    DataAvatar,
  },
  data() {
    return {
      dutyTypeHeaders: [
        {
          text: "",
          value: "icon",
          sortable: false,
          width: "2%",
        },
        {
          text: "Bezeichnung",
          value: "title",
        },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
        },
      ],

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "duty-management-config-tab-duty-types-duty-type-new",
            });
          },
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_create}`,
          actionStyle: "textBtn",
          function: this.toggleImportDialog,
        },
      ],

      helpers: {
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableDutyTypes,
          sortSelector: "sortKey",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
          snackbar: { model: false, text: "" },
        },
      },
    };
  },
  computed: {
    dutyTypes() {
      return this.$store.state.dutyManagement.dutyTypes;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      return this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_REPORT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    createItem() {
      this.$router.push({
        name: "duty-management-config-tab-duty-types-event-type-new",
      });
    },
    openDutyType(dutyType) {
      this.$router.push({
        name: "duty-management-config-tab-duty-types-duty-type-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: dutyType.meta.id,
        },
      });
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        this.$store
          .dispatch(`${DUTY_MANAGEMENT}${CREATE_REPORT_TYPES}`, {
            organizationId: this.$route.params.organizationId,

            dutyTypes: this.helpers.dataImport.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.dataImport.snackbar.model = true;
            this.helpers.dataImport.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Dienstart aus, um den Import duchrzuführen."
        );
      }
    },
    routeDetails(item) {
      this.$router.push({
        name: "duty-management-config-tab-duty-types-event-type-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
  },
};
</script>
