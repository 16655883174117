<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <duty-type-card :dutyType="dutyType"></duty-type-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>

    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import DutyTypeCard from "@/components/duty-management/DutyTypeCard.vue";
import {
  DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_update,
  DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_delete,
} from "@/data/permission-types.js";

export default {
  name: "member-management-skill-group-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    DutyTypeCard,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      dutyType: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "dutyType", data: this.dutyType }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyTypes")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.dutyType = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    editItem() {
      this.$router.push({
        name: "duty-management-config-tab-duty-types-duty-type-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      alert(
        "Fehler: Dienstarten können aktuell nicht gelöscht werden. Kontaktiere den Support, um Dienstarten zu löschen."
      );
    },
  },
};
</script>

<style></style>
