export const importableDutyTypes = [
  {
    title: "Alarmierung",
    shortTitle: "ALM",
    description: "bspw. Wachbesetzung",
    icon: "alarm-bell",
    color: "#F44336FF",
    sortKey: 1,
    meta: { id: "1" },
  },
  {
    title: "Einsatzdienst",
    shortTitle: "ED",
    description: "Alarmbereitschaftdienst",
    icon: "fire-truck",
    color: "#3F51B5FF",
    sortKey: 2,
    meta: { id: "2" },
  },
  {
    title: "Einsatzdienst (angeordnet)",
    shortTitle: "EDA",
    description: "Angeordneter Alarmbereitschaftdienst",
    icon: "fire-truck",
    color: "#3F51B5FF",
    sortKey: 3,
    meta: { id: "3" },
  },
  {
    title: "Übungsdienst",
    shortTitle: "ÜD",
    description: "Aus- und Fortbildung",
    icon: "school",
    color: "#009688FF",
    sortKey: 4,
    meta: { id: "4" },
  },
  {
    title: "Arbeitsdienst",
    shortTitle: "AD",
    description: "Wacharbeiten etc.",
    icon: "hammer",
    color: "#795548FF",
    sortKey: 5,
    meta: { id: "5" },
  },

  {
    title: "Sonstiger Dienst",
    shortTitle: "SD",
    description: "Allgemeine Erfassung",
    icon: "dots-horizontal-circle",
    color: "#607D8BFF",
    sortKey: 6,
    meta: { id: "6" },
  },
];
