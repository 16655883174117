<template>
  <div>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="openApprovalSteps" multiple>
          <v-expansion-panel
            v-for="(approvalStep, index) in approvalProcessLocal.default"
            :key="index"
          >
            <v-expansion-panel-header
              ><div class="subtitle-1 font-weight-medium">
                <v-avatar
                  color="blue lighten-4"
                  rounded
                  class="mr-5 blue--text text--darken-4 text-h6"
                  size="32"
                  >{{ index + 1 }}</v-avatar
                >
                <span v-if="approvalStep.title">{{ approvalStep.title }}</span>
                <!-- <span v-else class="grey--text">Ohne Bezeichnung</span> -->
              </div>
            </v-expansion-panel-header>
            <v-divider v-show="openApprovalSteps.includes(index)"></v-divider>
            <v-expansion-panel-content>
              <v-row class="mt-2">
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="approvalStep.approvalType"
                    :items="approvalTypeOptions"
                    label="Genehmigungsart"
                    hide-details="auto"
                    outlined
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="approvalStep.approvalPosition"
                    :items="approvalPositionOptions"
                    label="Genehmigende Stelle"
                    outlined
                    hide-details="auto"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <!-- depending on approvalStep.approvalPosition (supervisor -> no further selection; role -> select role; specificPersons -> select persons) -->
                  <span
                    v-if="approvalStep.approvalPosition === 'supervisor'"
                  ></span>
                  <v-select
                    v-else-if="approvalStep.approvalPosition === 'dutyPosition'"
                    v-model="approvalStep.approvalEntities"
                    :items="dutyPositions"
                    label="Dienststellung"
                    item-text="title"
                    item-value="id"
                    return-object
                    outlined
                    small-chips
                    deletable-chips
                    multiple
                    hide-details="auto"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-else-if="approvalStep.approvalPosition === 'assignment'"
                    v-model="approvalStep.approvalEntities"
                    :items="assignments"
                    label="Aufgabengebiet"
                    outlined
                    item-text="title"
                    item-value="id"
                    return-object
                    small-chips
                    deletable-chips
                    multiple
                    hide-details="auto"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-else-if="approvalStep.approvalPosition === 'role'"
                    v-model="approvalStep.approvalEntities"
                    :items="roles"
                    label="Rolle"
                    outlined
                    small-chips
                    deletable-chips
                    multiple
                    hide-details="auto"
                    :rules="[rules.required]"
                  ></v-select>
                  <v-select
                    v-else-if="
                      approvalStep.approvalPosition === 'specificPersons'
                    "
                    v-model="approvalStep.approvalEntities"
                    :items="persons"
                    label="Person"
                    outlined
                    small-chips
                    deletable-chips
                    multiple
                    hide-details="auto"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>

              <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :disabled="disableMoveUpBtn(approvalStep)"
                  @click="moveUp(approvalStep)"
                >
                  <v-icon dense> mdi-arrow-up </v-icon>
                </v-btn>
              </template>
              <span>Position nach oben bewegen</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :disabled="disableMoveDownBtn(approvalStep)"
                  @click="moveDown(approvalStep)"
                >
                  <v-icon dense> mdi-arrow-down </v-icon>
                </v-btn>
              </template>
              <span>Position nach unten bewegen</span>
            </v-tooltip> -->
              <v-row>
                <v-card-actions class="mx-1">
                  <v-btn
                    text
                    outlined
                    color="error"
                    @click="deleteApprovalStep(index)"
                  >
                    <v-icon dense left> mdi-delete </v-icon>Löschen
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel readonly class="px-3 py-2">
            <v-card-actions>
              <v-btn color="success" depressed @click="addApprovalStep()">
                <v-icon left>mdi-plus</v-icon>Hinzufügen
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" depressed @click="saveConfig()" disabled>
                <!-- :disabled="!approvalProcessLocal.default.length" -->
                <v-icon left>mdi-content-save</v-icon>Speichern
              </v-btn>
            </v-card-actions>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN, MEMBER_MANAGEMENT } from "@/store/modules.js";
import {
  GET_USER_LIST,
  GET_ASSIGNMENTS,
  GET_DUTY_POSITIONS,
} from "@/store/action-types.js";
export default {
  name: "approval-process-editor",
  props: {
    approvalProcess: {},
  },
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      openApprovalSteps: [],
      approvalPositionOptions: [
        {
          text: "Vorgesetzte Person/Direkte Führungskraft",
          value: "supervisor",
          disabled: true,
        },
        { text: "Person in Rolle", value: "role", disabled: true },
        { text: "Person mit Dienststellung", value: "dutyPosition" },
        { text: "Person in Aufgabengebiet", value: "assignment" },
        {
          text: "Bestimmte Personen",
          value: "specificPersons",
        },
      ],
      approvalTypeOptions: [
        {
          text: "Erforderliche Genehmigung",
          value: "requiredApproval",
          disabled: true,
        },
        { text: "Optionale Genehmigung", value: "optionalApproval" },
        {
          text: "Nur Kommentar/Stellungnahme",
          value: "commentOnly",
          disabled: true,
        },
        {
          text: "Nur Kenntnisnahme",
          value: "acknowledgementOnly",
          disabled: true,
        },
      ],
    };
  },
  model: {
    prop: "approvalProcess",
    event: "approvalProcessChange",
  },
  created() {
    this.initialized();
  },
  computed: {
    approvalProcessLocal: {
      get: function () {
        return this.approvalProcess;
      },
      set: function (value) {
        this.$emit("approvalProcessChange", value);
      },
    },
    roles() {
      return [];
      //   this.$store.state.dutyManagement.dutyTypes.map((dutyType) => {
      //     return {
      //       title: dutyType.title,
      //       id: dutyType.meta.id,
      //     };
      //   });
    },
    persons() {
      return [];
      //   this.$store.state.dutyManagement.dutyTypes.map((dutyType) => {
      //     return {
      //       title: dutyType.title,
      //       id: dutyType.meta.id,
      //     };
      //   });
    },
    assignments() {
      return this.$store.state.memberManagement.assignments.map(
        (assignment) => {
          return {
            title: assignment.title,
            id: assignment.meta.id,
          };
        }
      );
    },
    dutyPositions() {
      return this.$store.state.memberManagement.dutyPositions.map(
        (dutyPosition) => {
          return {
            title: dutyPosition.title,
            id: dutyPosition.meta.id,
          };
        }
      );
    },
  },
  methods: {
    initialized() {
      this.getDutyPositiions();
      this.getAssignments();
      this.getMemberships();
    },
    getDutyPositiions() {
      return this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DUTY_POSITIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    getAssignments() {
      return this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_ASSIGNMENTS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    getMemberships() {
      return this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    addApprovalStep() {
      const nextId = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("approvalSteps")
        .doc().id;
      this.approvalProcessLocal.default.push({
        title: "",
        approvalType: "requiredApproval",
        approvalPosition: "",
        approvalEntities: [],
        sortKey: this.approvalProcessLocal.default.length + 1,
        id: nextId,
      });
    },
    deleteApprovalStep(index) {
      this.approvalProcessLocal.default.splice(index, 1);
    },
    disableMoveUpBtn(item) {
      var index = this.approvalProcessLocal.default.indexOf(item);
      if (index === 0) {
        return true;
      } else {
        return false;
      }
    },
    disableMoveDownBtn(item) {
      var index = this.approvalProcessLocal.default.indexOf(item);
      var length = this.approvalProcessLocal.default.length;
      if (index === length - 1) {
        return true;
      } else if (index + 1 === length) {
        return true;
      } else {
        return false;
      }
    },
    moveUp(item) {
      const from = this.approvalProcessLocal.default.indexOf(item);
      const to = from - 1;
      this.moveElement(to, from);
    },
    moveDown(item) {
      const from = this.approvalProcessLocal.default.indexOf(item);
      const to = from + 1;
      this.moveElement(to, from);
    },
    moveElement(to, from) {
      this.approvalProcessLocal.default.splice(
        to,
        0,
        this.approvalProcessLocal.default.splice(from, 1)[0]
      );
      this.approvalProcessLocal.default = this.approvalProcessLocal.default.map(
        (approvalStep, index) => {
          const data = { ...approvalStep, sortKey: index + 1 };
          return data;
        }
      );
    },
  },
};
</script>
