<template>
  <div>
    <v-card :outlined="outlined">
      <v-list-item>
        <v-list-item-icon>
          <data-avatar
            :icon="dutyType.icon"
            :color="dutyType.color"
          ></data-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ dutyType.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ dutyType.description }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
export default {
  name: "duty-type-card",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    dutyType: {
      type: Object,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DataAvatar,
  },
  methods: {},
};
</script>
