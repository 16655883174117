<template>
  <div>
    <NavigationBar
      titel="Dienstart bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <duty-type-editor v-model="dutyType" mode="edit"></duty-type-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { UPDATE_REPORT_TYPE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DutyTypeEditor from "@/components/duty-management/DutyTypeEditor.vue";

export default {
  name: "duty-management-config-tab-duty-types-duty-type-edit",
  components: {
    NavigationBar,
    DutyTypeEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      dutyType: null,

      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("dutyTypes")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.dutyType = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    updateItem() {
      this.validate();

      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${DUTY_MANAGEMENT}${UPDATE_REPORT_TYPE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.dutyType.title,
          shortTitle: this.dutyType.shortTitle,
          description: this.dutyType.description,
          color: this.dutyType.color,
          sortKey: this.dutyType.sortKey,
          icon: this.dutyType.icon,
          prefill: this.dutyType.prefill,
          options: this.dutyType.options,
        });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
